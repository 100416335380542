export const masterWebhookTopics = [
  {
    name: 'Contacts',
    topics: [
      { key: 'user_created', text: 'User created' },
      { key: 'lead_created', text: 'Lead created' },
      { key: 'user_deleted', text: 'User deleted' },
      { key: 'lead_deleted', text: 'Lead deleted' },
      { key: 'contact_created', text: 'Contact created' },
      // TODO: Will update this feature later
      //{ key: 'contact_deleted', text: 'Contact deleted' },
      { key: 'contact_tagged', text: 'Contact tagged' },
      { key: 'contact_untagged', text: 'Contact untagged' },
      {
        key: 'custom_attribute_updated',
        text: 'Contact property value updated',
      },
      { key: 'contact_email_updated', text: 'Contact email updated' },
      { key: 'lead_submitted_email', text: 'Lead email submitted' },
      {
        key: 'contact_unsubscribed_emails',
        text: 'Contact unsubscribed from emails',
      },
    ],
  },
  {
    name: 'Conversations',
    topics: [
      {
        key: 'contact_initiated_conversation',
        text: 'Contact initiated conversation',
      },
      { key: 'conversation_assigned', text: 'Conversation assigned' },
      { key: 'conversation_opened', text: 'Conversation opened' },
      { key: 'conversation_closed', text: 'Conversation closed' },
      { key: 'conversation_rating', text: 'Conversation rating left' },
      { key: 'message_from_contact', text: 'New message received' },
      { key: 'teammate_replied', text: 'Teammate replied' },
      { key: 'note_added', text: 'Note added' },
    ],
  },
  {
    name: 'Events',
    topics: [
      {
        key: 'contact_subscribed_campaign',
        text: 'Contact subscribed to campaign',
      },
      {
        key: 'contact_unsubscribed_campaign',
        text: 'Contact unsubscribed from campaign',
      },
      { key: 'contact_performed_event', text: 'Contact performed event' },
      { key: 'contact_submitted_form', text: 'Contact submitted form' },
      { key: 'new_page_visit', text: 'Contact new page visited' },
    ],
  },
  {
    name: 'Meetings',
    topics: [
      { key: 'meeting_scheduled', text: 'Meeting scheduled' },
      { key: 'meeting_cancelled', text: 'Meeting cancelled' },
      { key: 'meeting_rescheduled', text: 'Meeting rescheduled' },
    ],
  },
];


export const topicKeyToTextMap = new Map([
  ["custom_attribute_updated", "Contact property value updated"],
  ["contact_unsubscribed_emails", "Contact unsubscribed from emails"],
  ["contact_subscribed_campaign", "Contact subscribed to campaign"],
  ["contact_unsubscribed_campaign", "Contact unsubscribed from campaign"],
  ["new_page_visit", "Contact new page visited"],
  ["conversation_rating", "Conversation rating left"],
  ["message_from_contact", "New message received"],
]);