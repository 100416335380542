import actions from './actions';
import {
  checkIsKeyPresent,
  convertStringToJson,
  handleSupportPermission,
  subscriptionStatus,
} from '../../helpers/utility';
import { setUpGuides } from '../constants';
import Settings from '../../settings';
import isElectron from 'is-electron';
import _ from "lodash";

const initState = {
  currentUserId: null,
  currentProjectSecretKey: null,
  profile: {},
  restrictedFeatures: [],
  errorMessage: '',
  authenticateUserLoader: false,
  createAccountLoader: false,
  companyDetailsLoader: false,
  companyName: '',
  domain: '',
  userExist: '',
  onBoardingState: '',
  invitedEmail: '',
  inviteLoader: false,
  forgotPasswordLoader: false,
  changePasswordLoader: false,
  projects: [],
  workSpaceLoader: false,
  currentProjectName: null,
  openWorkSpaceDrawer: false,
  createWorkSpaceLoader: false,
  projectBlocked: false,
  beta: false,
  owner: null,
  authenticateLoader: false,
  analyticsData: [],
  allowOutgoingAttachments: false,
  bannerMessages: {},
  plan: null,
  daysLeftInProTrial: null,
  isAuthenticating: false,
  paymentFailed: false,
  projectOwnerEmail: null,
  getStartedData: [],
  isGetStarted: true,
  isGetStartedLoader: false,
  completedCount: 1,
  planDetail: [],
  selectedPlanType: null,
  buttonLoader: false,
  loader: false,
  desktopNotifications: {},
  emailSendingModalVisible: false,
  emailSendingLoader: false,
  discountFormLoader: false,
  defaultRedirectionPage: null,
  templateEnabled: false,
  satisfactionRating: '',
  satisfactionComment: '',
  satisfactionRatingLoader: false,
  isSatisfactionRatingSubmitted: false,
  satisfactionRatingCommentLoader: false,
  confirmationSettingsLoader: false,
  redirectionStatus: false,
  displayPowerLinkStatus: false,
  satisfactionStatus: '',
  teamRestrictedIds: [],
  isPaid: false,
  isBlackFridayUpgradeEligible: false,
  isPremiumWorkspace: false,
  isUpgradePrime: false,
  isPrimeCouponUser: false,
  planEquivalent: '',
  allowedDomains: 0,
  isAudioNotificationEnabled: false,
  propertyPreference: null,
  inboxPropertyPreference: null,
  openConversationCount: null,
  product: {
    domain: 'web-testing.getgist.com',
    fav_icon: `${Settings.STATIC_HOST}/white-label/gist/favicon.ico`,
    logo: `${Settings.STATIC_HOST}/white-label/gist/logo.png`,
    name: 'Gist',
    primary_colour: '#1890ff',
  },
  domainLevelRestriction: [],
  canFilterByFullName: false,
  userPreferences: {
    theme: 'auto',
  },
  currentUserTheme: 'light',
  notAuthorizedModalStatus: false,
  userPermissions: {},
  userSecret: null,
  userRole: null,
  subscriptionTypes: [],
  contactSubscriptions: [],
  unsubscriptionData: {},
  isEmptySubscriptions: false,
  alertMessage: '',
  isResubscribeEmails: false,
  isFetchUnsubscriptionFailed: false,
  linkLoader: false,
  elasticSearchEnabled: false,
  findAppByClientIdLoader: false,
  appByClientId: {},
  calendarsMaximumLimit: 0,
  isAnalyticsScriptInstalled: false,
  authorizeAppProjectSecretKey: '',
  satisfactionTranslateKeys: null,
  primePlanIds: {},
  couponsData: {},
  isPrimeWorkspaceEnabled: false,
  addonDetails: {},
  addonLoader: false,
  shopifyDrawerStatus: false,
  currentPaymentMethod: null,
  chargeConfirmationUrl: '',
  hideInfoBanner: [],
  currentStep: null,
  currentProduct: null,
  completedOnboardingTasks: [],
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.AUTHORIZATION_SUCCESS:
      return {
        ...state,
        currentUserId: checkIsKeyPresent(action.payload.data.current_user_id),
        currentProjectSecretKey: checkIsKeyPresent(
          action.payload.data.project_secret_key,
        ),
        profile: checkIsKeyPresent(action.payload.data.profile),
        restrictedFeatures: checkIsKeyPresent(
          action.payload.data.restricted_features,
        ),
        onBoardingState: checkIsKeyPresent(
          action.payload.data.onboarding_state,
        ),
        currentProjectName: checkIsKeyPresent(action.payload.data.project_name),
        projectBlocked: checkIsKeyPresent(action.payload.data.project_blocked),
        beta: checkIsKeyPresent(action.payload.data.beta),
        owner: checkIsKeyPresent(action.payload.data.owner),
        authenticateLoader: false,
        analyticsData: checkIsKeyPresent(action.payload.data.analytics_data),
        allowOutgoingAttachments: checkIsKeyPresent(
          action.payload.data.allow_outgoing_attachments,
        ),
        bannerMessages: checkIsKeyPresent(action.payload.data.banner_messages),
        plan: checkIsKeyPresent(action.payload.data.plan),
        paymentFailed: checkIsKeyPresent(action.payload.data.payment_failed),
        daysLeftInProTrial: action.payload.data.days_left_in_pro_trial,
        projectOwnerEmail: checkIsKeyPresent(
          action.payload.data.project_owner_email,
        ),
        isAuthenticating: false,
        desktopNotifications: action.payload.data.desktop_notification_details,
        templateEnabled: action.payload.data.template_enabled,
        defaultRedirectionPage: action.payload.data.default_redirection_page,
        domain: action.payload.data.domain,
        teamRestrictedIds: action.payload.data.team_ids,
        isPaid: action.payload.data.is_paid,
        isBlackFridayUpgradeEligible: action.payload.data.eligible_for_bf_2024,
        isPremiumWorkspace: action.payload.data.premium_workspace,
        isUpgradePrime: action.payload.data.upgrade_to_prime,
        isPrimeCouponUser: action.payload.data.is_prime_coupon_user,
        planEquivalent: action.payload.data.prime_plan_equivalent,
        allowedDomains: action.payload.data.allowed_domains,
        isAudioNotificationEnabled:
          action.payload.data.is_audio_notification_enabled,
        propertyPreference:
          action.payload.data.user_preference.property_preference,
        inboxPropertyPreference:
          action.payload.data.user_preference.inbox_preference,
        openConversationCount: action.payload.data.open_conversations_count,
        product: checkIsKeyPresent(action.payload.data.product),
        domainLevelRestriction: checkIsKeyPresent(action.payload.data.domain_level_restricted_features),
        userPermissions: checkIsKeyPresent(handleSupportPermission(
          action.payload.data.permissions_data,
        )),
        canFilterByFullName: action.payload.data.can_filter_by_full_name,
        userPreferences: action.payload.data.user_preference,
        hideInfoBanner:
          action.payload.data.user_preference?.hidden_info_widgets,
        userSecret: action.payload.data.user_channel,
        userRole: checkIsKeyPresent(action.payload.data.project_role),
        elasticSearchEnabled: action.payload.data.es_enabled,
        currentPaymentMethod: action.payload.data.current_payment_method,
        chargeConfirmationUrl: action.payload.data.charge_confirmation_url,
        calendarsMaximumLimit: action.payload.data.calendars_maximum_limit,
        couponsData: { ...action.payload.data.coupons },
      };
    case actions.AUTHORIZATION_ERROR:
      return {
        ...state,
        currentUserId: null,
        currentProjectSecretKey: null,
        profile: {},
        restrictedFeatures: [],
        currentProjectName: null,
        projectBlocked: false,
        authenticateLoader: false,
        bannerMessages: {},
        owner: false,
        isAuthenticating: false,
        paymentFailed: false,
        projectOwnerEmail: null,
        desktopNotifications: {},
        allowOutgoingAttachments: false,
        templateEnabled: false,
        defaultRedirectionPage: null,
        domain: '',
        teamRestrictedIds: [],
        isPaid: false,
        isBlackFridayUpgradeEligible: false,
        isPremiumWorkspace: false,
        isUpgradePrime: false,
        isPrimeCouponUser: false,
        planEquivalent: '',
        allowedDomains: 0,
        isAudioNotificationEnabled: false,
        propertyPreference: null,
        inboxPropertyPreference: null,
        openConversationCount: null,
        domainLevelRestriction: [],
        userPermissions: {},
        userSecret: null,
        elasticSearchEnabled: false,
        currentPaymentMethod: null,
        chargeConfirmationUrl: '',
        couponsData: {},
        plan: null,
      };
    case actions.SET_AGENT_STATUS:
      return {
        ...state,
        profile: {
          ...state.profile,
          agent_status: action.payload,
        },
      };
    case actions.AGENT_AWAY_STATUS_FAILURE:
      return {
        ...state,
        profile: {
          ...state.profile,
          agent_status: action.payload === 'online' ? 'offline' : 'online',
        },
      };
    case actions.AUTHENTICATE_USER:
      return {
        ...state,
        authenticateUserLoader: true,
        errorMessage: '',
      };
    case actions.AUTHENTICATE_USER_SUCCESS:
      localStorage.setItem('isAllowNotification', false);
      return {
        ...state,
        authenticateUserLoader: false,
        currentUserId: checkIsKeyPresent(action.payload.data.current_user_id),
        currentProjectSecretKey: checkIsKeyPresent(
          action.payload.data.project_secret_key,
        ),
        profile: checkIsKeyPresent(action.payload.data.profile),
        restrictedFeatures: checkIsKeyPresent(
          action.payload.data.restricted_features,
        ),
        onBoardingState: checkIsKeyPresent(
          action.payload.data.onboarding_state,
        ),
        currentProjectName: checkIsKeyPresent(action.payload.data.project_name),
        projectBlocked: checkIsKeyPresent(action.payload.data.project_blocked),
        beta: checkIsKeyPresent(action.payload.data.beta),
        errorMessage: '',
        analyticsData: checkIsKeyPresent(action.payload.data.analytics_data),
        bannerMessages: checkIsKeyPresent(action.payload.data.banner_messages),
        owner: checkIsKeyPresent(action.payload.data.owner),
        paymentFailed: checkIsKeyPresent(action.payload.data.payment_failed),
        projectOwnerEmail: checkIsKeyPresent(
          action.payload.data.project_owner_email,
        ),
        desktopNotifications: action.payload.data.desktop_notification_details,
        allowOutgoingAttachments: checkIsKeyPresent(
          action.payload.data.allow_outgoing_attachments,
        ),
        templateEnabled: action.payload.data.template_enabled,
        defaultRedirectionPage: action.payload.data.default_redirection_page,
        plan: checkIsKeyPresent(action.payload.data.plan),
        domain: action.payload.data.domain,
        teamRestrictedIds: action.payload.data.team_ids,
        isPaid: action.payload.data.is_paid,
        isBlackFridayUpgradeEligible: action.payload.data.eligible_for_bf_2024,
        isPremiumWorkspace: action.payload.data.premium_workspace,
        isUpgradePrime: action.payload.data.upgrade_to_prime,
        isPrimeCouponUser: action.payload.data.is_prime_coupon_user,
        planEquivalent: action.payload.data.prime_plan_equivalent,
        allowedDomains: action.payload.data.allowed_domains,
        isAudioNotificationEnabled:
          action.payload.data.is_audio_notification_enabled,
        propertyPreference:
          action.payload.data.user_preference.property_preference,
        openConversationCount: action.payload.data.open_conversations_count,
        product: checkIsKeyPresent(action.payload.data.product),
        domainLevelRestriction: checkIsKeyPresent(action.payload.data.domain_level_restricted_features),
        userPermissions: checkIsKeyPresent(handleSupportPermission(
          action.payload.data.permissions_data,
        )),
        canFilterByFullName: action.payload.data.can_filter_by_full_name,
        userPreferences: action.payload.data.user_preference,
        hideInfoBanner:
          action.payload.data.user_preference?.hidden_info_widgets,
        userSecret: action.payload.data.user_channel,
        userRole: checkIsKeyPresent(action.payload.data.project_role),
        elasticSearchEnabled: action.payload.data.es_enabled,
        currentPaymentMethod: action.payload.data.current_payment_method,
        chargeConfirmationUrl: action.payload.data.charge_confirmation_url,
        calendarsMaximumLimit: action.payload.data.calendars_maximum_limit,
        couponsData: { ...action.payload.data.coupons },
        inboxPropertyPreference:
          action.payload.data.user_preference.inbox_preference,
      };
    case actions.AUTHENTICATE_USER_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        authenticateUserLoader: false,
        currentUserId: null,
        currentProjectSecretKey: null,
        profile: {},
        restrictedFeatures: [],
        currentProjectName: null,
        projectBlocked: false,
        analyticsData: [],
        bannerMessages: {},
        owner: false,
        projectOwnerEmail: null,
        desktopNotifications: {},
        allowOutgoingAttachments: false,
        templateEnabled: false,
        defaultRedirectionPage: null,
        domain: '',
        teamRestrictedIds: [],
        isPaid: false,
        isBlackFridayUpgradeEligible: false,
        isPremiumWorkspace: false,
        isUpgradePrime: false,
        isPrimeCouponUser: false,
        planEquivalent: '',
        allowedDomains: 0,
        isAudioNotificationEnabled: false,
        propertyPreference: null,
        inboxPropertyPreference: null,
        openConversationCount: null,
        domainLevelRestriction: [],
        userPermissions: {},
        userSecret: null,
        elasticSearchEnabled: false,
        currentPaymentMethod: null,
        chargeConfirmationUrl: '',
        couponsData: {},
        plan: null,
      };
    case actions.CREATE_USER_ACCOUNT:
      return {
        ...state,
        createAccountLoader: true,
      };
    case actions.CREATE_USER_ACCOUNT_SUCCESS:
      return {
        ...state,
        createAccountLoader: false,
        currentUserId: action.payload.data.current_user_id,
        currentProjectSecretKey: action.payload.data.project_secret_key,
        profile: action.payload.data.profile,
        restrictedFeatures: action.payload.data.restricted_features,
        currentProjectName: action.payload.data.project_name,
        onBoardingState: action.payload.data.onboarding_state,
        projectBlocked: action.payload.data.project_blocked,
        beta: action.payload.data.beta,
        analyticsData: action.payload.data.analytics_data,
        bannerMessages: action.payload.data.banner_messages,
        owner: action.payload.data.owner,
        paymentFailed: checkIsKeyPresent(action.payload.data.payment_failed),
        projectOwnerEmail: checkIsKeyPresent(
          action.payload.data.project_owner_email,
        ),
        desktopNotifications: action.payload.data.desktop_notification_details,
        allowOutgoingAttachments: checkIsKeyPresent(
          action.payload.data.allow_outgoing_attachments,
        ),
        userPermissions: checkIsKeyPresent(
          handleSupportPermission(action.payload.data.permissions_data),
        ),
        templateEnabled: action.payload.data.template_enabled,
        domain: action.payload.data.domain,
        teamRestrictedIds: action.payload.data.team_ids,
        isPaid: action.payload.data.is_paid,
        isBlackFridayUpgradeEligible: action.payload.data.eligible_for_bf_2024,
        isPremiumWorkspace: action.payload.data.premium_workspace,
        isUpgradePrime: action.payload.data.upgrade_to_prime,
        isPrimeCouponUser: action.payload.data.is_prime_coupon_user,
        planEquivalent: action.payload.data.prime_plan_equivalent,
        allowedDomains: action.payload.data.allowed_domains,
        isAudioNotificationEnabled:
          action.payload.data.is_audio_notification_enabled,
        propertyPreference:
          action.payload.data.user_preference.property_preference,
        inboxPropertyPreference:
          action.payload.data.user_preference.inbox_preference,
        openConversationCount: action.payload.data.open_conversations_count,
        product: checkIsKeyPresent(action.payload.data.product),
        domainLevelRestriction: checkIsKeyPresent(
          action.payload.data.domain_level_restricted_features,
        ),
        canFilterByFullName: action.payload.data.can_filter_by_full_name,
        userPreferences: action.payload.data.user_preference,
        hideInfoBanner:
          action.payload.data.user_preference?.hidden_info_widgets,
        userSecret: action.payload.data.user_channel,
        userRole: checkIsKeyPresent(action.payload.data.project_role),
        elasticSearchEnabled: action.payload.data.es_enabled,
        currentPaymentMethod: action.payload.data.current_payment_method,
        chargeConfirmationUrl: action.payload.data.charge_confirmation_url,
        calendarsMaximumLimit: action.payload.data.calendars_maximum_limit,
        couponsData: { ...action.payload.data.coupons },
        plan: checkIsKeyPresent(action.payload.data.plan),
      };
    case actions.CREATE_USER_ACCOUNT_FAILURE:
      return {
        ...state,
        createAccountLoader: false,
      };
    case actions.CREATE_COMPANY_DETAILS:
      return {
        ...state,
        companyDetailsLoader: true,
      };
    case actions.CREATE_COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        companyDetailsLoader: false,
        currentUserId: action.payload.data.current_user_id,
        currentProjectSecretKey: action.payload.data.project_secret_key,
        profile: action.payload.data.profile,
        restrictedFeatures: action.payload.data.restricted_features,
        onBoardingState: action.payload.data.onboarding_state,
        currentProjectName: action.payload.data.project_name,
        projectBlocked: action.payload.data.project_blocked,
        beta: action.payload.data.beta,
        analyticsData: action.payload.data.analytics_data,
        bannerMessages: action.payload.data.banner_messages,
        owner: action.payload.data.owner,
        paymentFailed: checkIsKeyPresent(action.payload.data.payment_failed),
        projectOwnerEmail: checkIsKeyPresent(
          action.payload.data.project_owner_email,
        ),
        desktopNotifications: action.payload.data.desktop_notification_details,
        allowOutgoingAttachments: checkIsKeyPresent(
          action.payload.data.allow_outgoing_attachments,
        ),
        templateEnabled: action.payload.data.template_enabled,
        plan: checkIsKeyPresent(action.payload.data.plan),
        domain: action.payload.data.domain,
        teamRestrictedIds: action.payload.data.team_ids,
        isAudioNotificationEnabled:
          action.payload.data.is_audio_notification_enabled,
        propertyPreference:
          action.payload.data.user_preference.property_preference,
        inboxPropertyPreference:
          action.payload.data.user_preference.inbox_preference,
        openConversationCount: action.payload.data.open_conversations_count,
        product: checkIsKeyPresent(action.payload.data.product),
        domainLevelRestriction: checkIsKeyPresent(action.payload.data.domain_level_restricted_features),
        userPermissions: checkIsKeyPresent(handleSupportPermission(
          action.payload.data.permissions_data,
        )),
        userPreferences: action.payload.data.user_preference,
        hideInfoBanner:
          action.payload.data.user_preference?.hidden_info_widgets,
        userSecret: action.payload.data.user_channel,
        userRole: checkIsKeyPresent(action.payload.data.project_role),
        elasticSearchEnabled: action.payload.data.es_enabled,
        currentPaymentMethod: action.payload.data.current_payment_method,
        chargeConfirmationUrl: action.payload.data.charge_confirmation_url,
        calendarsMaximumLimit: action.payload.data.calendars_maximum_limit,
        couponsData: { ...action.payload.data.coupons },
      };
    case actions.CREATE_COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        companyDetailsLoader: false,
      };
    case actions.GET_COMPANY_DETAILS_FROM_CLEAR_BIT_SUCCESS:
      return {
        ...state,
        companyName: action.payload.name,
        domain: action.payload.domain,
      };
    case actions.GET_INVITE_USER_DETAILS:
      return {
        ...state,
        inviteLoader: true,
      };
    case actions.GET_INVITE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userExist: action.payload.data.user_exist,
        invitedEmail: action.payload.data.email,
        inviteLoader: false,
      };
    case actions.REQUEST_FORGOT_PASSWORD_LINK:
      return {
        ...state,
        forgotPasswordLoader: true,
      };
    case actions.REQUEST_FORGOT_PASSWORD_LINK_SUCCESS:
      return {
        ...state,
        forgotPasswordLoader: false,
      };
    case actions.REQUEST_FORGOT_PASSWORD_LINK_FAILURE:
      return {
        ...state,
        forgotPasswordLoader: false,
      };
    case actions.CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordLoader: true,
      };
    case actions.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoader: false,
        currentUserId: action.payload.data.current_user_id,
        currentProjectSecretKey: action.payload.data.project_secret_key,
        profile: action.payload.data.profile,
        restrictedFeatures: action.payload.data.restricted_features,
        currentProjectName: action.payload.data.project_name,
        onBoardingState: action.payload.data.onboarding_state,
        projectBlocked: action.payload.data.project_blocked,
        beta: action.payload.data.beta,
        analyticsData: action.payload.data.analytics_data,
        bannerMessages: action.payload.data.banner_messages,
        paymentFailed: checkIsKeyPresent(action.payload.data.payment_failed),
        projectOwnerEmail: checkIsKeyPresent(
          action.payload.data.project_owner_email,
        ),
        desktopNotifications: action.payload.data.desktop_notification_details,
        templateEnabled: action.payload.data.template_enabled,
        domain: action.payload.data.domain,
        teamRestrictedIds: action.payload.data.team_ids,
        propertyPreference:
          action.payload.data.user_preference.property_preference,
        inboxPropertyPreference:
          action.payload.data.user_preference.inbox_preference,
        openConversationCount: action.payload.data.open_conversations_count,
        userPreferences: action.payload.data.user_preference,
        hideInfoBanner:
          action.payload.data.user_preference?.hidden_info_widgets,
        userPermissions: checkIsKeyPresent(
          handleSupportPermission(action.payload.data.permissions_data),
        ),
        elasticSearchEnabled: action.payload.data.es_enabled,
        currentPaymentMethod: action.payload.data.current_payment_method,
        chargeConfirmationUrl: action.payload.data.charge_confirmation_url,
        calendarsMaximumLimit: action.payload.data.calendars_maximum_limit,
        couponsData: { ...action.payload.data.coupons },
        plan: checkIsKeyPresent(action.payload.data.plan),
      };
    case actions.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordLoader: false,
      };
    case actions.CLEAR_ERROR: {
      return {
        ...state,
        errorMessage: '',
      };
    }
    case actions.SIGN_OUT_SUCCESS: {
      return initState;
    }
    case actions.GET_RECENT_PROJECTS_SUCCESS: {
      if(isElectron) {
        window.postMessage({
          type: 'projects_list',
          data: JSON.stringify(action.payload.data.projects),
        });
      }

      return {
        ...state,
        projects: action.payload.data.projects,
      };
    }
    case actions.GET_ALL_PROJECTS: {
      return {
        ...state,
        workSpaceLoader: true,
        projects: [],
      };
    }
    case actions.GET_ALL_PROJECTS_SUCCESS: {
      return {
        ...state,
        workSpaceLoader: false,
        projects: action.payload,
      };
    }
    case actions.OPEN_WORKSPACE_DRAWER: {
      return {
        ...state,
        openWorkSpaceDrawer: true,
      };
    }
    case actions.CLOSE_WORKSPACE_DRAWER: {
      return {
        ...state,
        openWorkSpaceDrawer: false,
      };
    }
    case actions.CREATE_WORKSPACE: {
      return {
        ...state,
        createWorkSpaceLoader: true,
      };
    }
    case actions.CREATE_WORKSPACE_SUCCESS: {
      return {
        ...state,
        createWorkSpaceLoader: false,
        currentUserId: action.payload.data.current_user_id,
        currentProjectSecretKey: action.payload.data.project_secret_key,
        profile: action.payload.data.profile,
        restrictedFeatures: action.payload.data.restricted_features,
        onBoardingState: action.payload.data.onboarding_state,
        currentProjectName: action.payload.data.project_name,
        projectBlocked: action.payload.data.project_blocked,
        beta: action.payload.data.beta,
        openWorkSpaceDrawer: false,
        bannerMessages: action.payload.data.banner_messages,
        owner: action.payload.data.owner,
        paymentFailed: checkIsKeyPresent(action.payload.data.payment_failed),
        projectOwnerEmail: checkIsKeyPresent(
          action.payload.data.project_owner_email,
        ),
        desktopNotifications: action.payload.data.desktop_notification_details,
        allowOutgoingAttachments: checkIsKeyPresent(
          action.payload.data.allow_outgoing_attachments,
        ),
        templateEnabled: action.payload.data.template_enabled,
        domain: action.payload.data.domain,
        teamRestrictedIds: action.payload.data.team_ids,
        propertyPreference:
          action.payload.data.user_preference.property_preference,
        inboxPropertyPreference:
          action.payload.data.user_preference.inbox_preference,
        openConversationCount: action.payload.data.open_conversations_count,
        canFilterByFullName: action.payload.data.can_filter_by_full_name,
        userPreferences: action.payload.data.user_preference,
        hideInfoBanner:
          action.payload.data.user_preference?.hidden_info_widgets,
        userPermissions: checkIsKeyPresent(
          handleSupportPermission(action.payload.data.permissions_data),
        ),
        userSecret: action.payload.data.user_channel,
        elasticSearchEnabled: action.payload.data.es_enabled,
        currentPaymentMethod: action.payload.data.current_payment_method,
        chargeConfirmationUrl: action.payload.data.charge_confirmation_url,
        calendarsMaximumLimit: action.payload.data.calendars_maximum_limit,
        couponsData: { ...action.payload.data.coupons },
        plan: checkIsKeyPresent(action.payload.data.plan),
      };
    }
    case actions.CREATE_WORKSPACE_FAILURE: {
      return {
        ...state,
        createWorkSpaceLoader: false,
      };
    }
    case actions.CHECK_AUTHORIZATION: {
      return {
        ...state,
        authenticateLoader: true,
        isAuthenticating: true,
      };
    }
    case actions.AUTH_LOADER: {
      return {
        ...state,
        isAuthenticating: false,
      };
    }
    case actions.UPDATE_CHANGED_PROFILE_DETAILS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
          full_name: `${action.payload.first_name} ${action.payload.last_name}`,
        },
      };
    case actions.REMOVE_PROJECT_SECRET_KEY:
      return {
        ...state,
        currentProjectSecretKey: null,
      };
    case actions.UPDATE_WORKSPACE_NAME: {
      return {
        ...state,
        currentProjectName: action.payload,
      };
    }
    case actions.FETCH_GET_STARTED_DATA_SUCCESS: {
      let completedCount = 1;
      const { setup_guide } = action.payload.data;
      if (setup_guide.enabled) {
        let data = setup_guide;
        Object.keys(data).forEach((value) => {
          if (setUpGuides.includes(value)) {
            completedCount += data[value].length;
          }
        });
      }
      return {
        ...state,
        getStartedData: setup_guide,
        isGetStarted: setup_guide.enabled,
        completedCount: completedCount,
        isGetStartedLoader: false,
        completedOnboardingTasks: setup_guide.completed_keys,
      };
    }
    case actions.FETCH_GET_STARTED_DATA: {
      return {
        ...state,
        isGetStartedLoader: true,
      };
    }
    case actions.FETCH_GET_STARTED_DATA_FAILURE: {
      return {
        ...state,
        isGetStartedLoader: false,
      };
    }
    case actions.FETCH_ELIGIBLE_PLAN_DETAIL_SUCCESS: {
      return {
        ...state,
        planDetail: action.payload.subscription_plans,
      };
    }
    case actions.SET_PLAN_TYPE: {
      return {
        ...state,
        selectedPlanType: action.payload,
      };
    }
    case actions.UPGRATE_PLAN_WITH_TRIAL: {
      return {
        ...state,
        buttonLoader: true,
      };
    }
    case actions.SET_BUTTON_LOADER: {
      return {
        ...state,
        buttonLoader: action.payload,
      };
    }
    case actions.UPDATE_ONBOARD_STATE: {
      return {
        ...state,
        loader: true,
      };
    }
    case actions.SET_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case actions.UPDATE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        desktopNotifications: action.payload,
      };
    case actions.EMAIL_SENDING_DOMAIN_SUCCESS:
      return {
        ...state,
        bannerMessages: {},
        emailSendingLoader: false,
        emailSendingModalVisible: false,
      };
    case actions.ENABLE_EMAIL_SENDING_DOMAIN:
      return {
        ...state,
        emailSendingLoader: true,
      };
    case actions.EMAIL_SENDING_DOMAIN_FAILURE:
      return {
        ...state,
        emailSendingLoader: false,
      };
    case actions.EMAIL_SENDING_DOMAIN_MODEL_TRIGGER:
      return {
        ...state,
        emailSendingModalVisible: action.payload,
      };
    case actions.SET_SATISFACTION_RATING_LOADER:
      return {
        ...state,
        satisfactionRatingLoader: action.payload,
      };
    case actions.UPDATE_SATISFACTION_RATING_SUCCESS:
      return {
        ...state,
        satisfactionRating: convertStringToJson(
          action.payload.message.message_content,
        ).rating,
        satisfactionComment: convertStringToJson(
          action.payload.message.message_content,
        ).comment,
        satisfactionStatus: action.payload.message.satisfaction_status,
        isSatisfactionRatingSubmitted: true,
        satisfactionTranslateKeys: action.payload.translate_keys,
      };
    case actions.SET_SATISFACTION_RATING_COMMENT_LOADER:
      return {
        ...state,
        satisfactionRatingCommentLoader: action.payload,
      }
    case actions.DISCOUNT_PAGE_LOADER:
      return {
        ...state,
        discountFormLoader: action.payload,
      };
    case actions.UPDATE_CONFIRMATION_SETTINGS_REDIRECTION:
      return {
        ...state,
        confirmationSettingsLoader: true,
        redirectionStatus: false,
        displayPowerLinkStatus: false,
      };
    case actions.UPDATE_CONFIRMATION_SETTINGS_REDIRECTION_SUCCESS:
      return {
        ...state,
        confirmationSettingsLoader: false,
        redirectionStatus: true,
        displayPowerLinkStatus: action.payload.display_powered_by_link,
      };
    case actions.UPDATE_CONFIRMATION_SETTINGS_REDIRECTION_FAILURE:
      return {
        ...state,
        confirmationSettingsLoader: false,
        redirectionStatus: false,
        displayPowerLinkStatus: false,
      };
    case actions.SET_INBOX_AUDIO_NOTIFICATION:
      return {
        ...state,
        isAudioNotificationEnabled: action.payload,
      };
    case actions.UPDATE_PROPERTY_PREFERENCE:
      if (action.isFromTableView || action.isFromSearchView) {
        return {
          ...state,
          inboxPropertyPreference: action.payload.toString(),
        };
      }
      return {
        ...state,
        propertyPreference: action.payload.toString(),
      };
    case actions.UPDATE_PROPERTY_PREFERENCE_SUCCESS:
      return {
        ...state,
        propertyPreference: action.payload || state.propertyPreference,
        inboxPropertyPreference:
          action.inboxPreference || state.inboxPropertyPreference,
      };
    case actions.UPDATE_OPEN_CONVERSATION_COUNT:
      return {
        ...state,
        openConversationCount: action.payload,
      };
    case actions.UPDATE_PRODUCT_DETAIL:
      return {
        ...state,
        product: checkIsKeyPresent(action.payload.data.product),
      };
    case actions.SET_NOT_AUTHORIZED_MODAL_STATUS:
      return {
        ...state,
        notAuthorizedModalStatus: action.payload,
      };
    case actions.UPDATE_DISPLAY_PREFERENCE:
      return {
        ...state,
        userPreferences: {
          theme: action.payload,
        },
      };
    case actions.SET_CURRENT_USER_THEME:
      return {
        ...state,
        currentUserTheme: action.payload,
      }
    case actions.UPDATE_USER_PERMISSION_DATA:
      return {
        ...state,
        userPermissions: handleSupportPermission(action.payload),
      };
    case actions.GET_UNSUBSCRIPTION_PERSON_DATA:
      return {
        ...state,
        loader: true,
      };
    case actions.GET_UNSUBSCRIPTION_PERSON_DATA_SUCCESS:
      let activeSubscriptions = action.payload.data.mail_subscriptions
        ? action.payload.data.mail_subscriptions.filter(
            (item) => item.status === 'published',
          )
        : [];
      return {
        ...state,
        unsubscriptionData: action.payload,
        subscriptionTypes: activeSubscriptions,
        contactSubscriptions: action.payload.data.mail_subscriptions_person
          ? subscriptionStatus(
              activeSubscriptions,
              action.payload.data.mail_subscriptions_person,
            )
          : [],
        loader: false,
        isEmptySubscriptions: !activeSubscriptions.length,
        isResubscribeEmails: !activeSubscriptions.length,
        alertMessage: action.payload.message,
      };
    case actions.GET_UNSUBSCRIPTION_PERSON_DATA_FAILURE:
      return {
        ...state,
        loader: false,
        isEmptySubscriptions: true,
        alertMessage: action.message,
        isFetchUnsubscriptionFailed: true,
      };
    case actions.UPDATE_UNSUBSCRIPTION_PERSON_DATA:
      const selectLoader = action.payload.unsubscribe_from_all_emails;
      return {
        ...state,
        linkLoader: selectLoader,
        buttonLoader: !selectLoader,
      };
    case actions.UPDATE_UNSUBSCRIPTION_PERSON_DATA_SUCCESS:
      return {
        ...state,
        alertMessage: action.message,
        contactSubscriptions: action.payload.mail_subscription_ids,
        linkLoader: false,
        buttonLoader: false,
      };
    case actions.UPDATE_UNSUBSCRIPTION_PERSON_DATA_FAILURE:
      return {
        ...state,
        linkLoader: false,
        buttonLoader: false,
      };
    case actions.RESUBSCRIBE_EMAIL_PREFERENCES_SUCCESS:
      return {
        ...state,
        alertMessage: action.message,
        isResubscribeEmails: false,
      };
    case actions.FIND_APP_BY_CLIENT_ID:
      return {
        ...state,
        findAppByClientIdLoader: true,
      };
    case actions.FIND_APP_BY_CLIENT_ID_SUCCESS:
      return {
        ...state,
        findAppByClientIdLoader: false,
        appByClientId: action.payload.developer_app,
      };
    case actions.FIND_APP_BY_CLIENT_ID_FAILURE:
      return {
        ...state,
        findAppByClientIdLoader: false,
      };
    case actions.SHOPIFY_PLAN_DRAWER_STATUS:
      return {
        ...state,
        shopifyDrawerStatus: action.payload,
      };
    case actions.CONNECT_SHOPIFY_FROM_APP:
      return {
        ...state,
        loader: true,
      };
    case actions.CONNECT_SHOPIFY_FROM_APP_SUCCESS:
      return {
        ...state,
        loader: false,
      };
    case actions.CONNECT_SHOPIFY_FROM_APP_FAILURE:
      return {
        ...state,
        loader: false,
      };
    case actions.HIDE_CONVERSATION_STATUS_MESSAGES_SUCCESS:
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          hide_conversation_status_messages: action.payload,
        },
      };
    case actions.UPDATE_ANALYTICS_TRACK_SCRIPTS: {
      return {
        ...state,
        isAnalyticsScriptInstalled: action.payload || false,
      };
    }
    case actions.SET_AUTHORIZE_APP_PROJECT_SECRET_KEY: {
      return {
        ...state,
        authorizeAppProjectSecretKey: action.payload,
      };
    }
    case actions.UPDATE_PRIME_PLAN_ID: {
      const {
        id,
        payload: { plan_type, coupons_count },
      } = action;
      return {
        ...state,
        primePlanIds: {
          ...state.primePlanIds,
          [`${plan_type}_${coupons_count}stack`]: id,
        },
      };
    }
    case actions.SET_PRIME_WORKSPACE_ENABLED:
      return {
        ...state,
        isPrimeWorkspaceEnabled: action.payload,
      };
    case actions.FETCH_ADDON_DETAILS_SUCCESS:
      return {
        ...state,
        addonDetails: action.payload,
      };
    case actions.UPDATE_ADDON_DETAILS_SUCCESS: {
      let restrictedFeatures = _.cloneDeep(state.restrictedFeatures);
      restrictedFeatures = restrictedFeatures.filter(
        (feature) => feature !== action.payload,
      );
      return {
        ...state,
        restrictedFeatures: restrictedFeatures,
      };
    }
    case actions.ADDON_LOADER:
      return {
        ...state,
        addonLoader: action.payload,
      };
    case actions.SET_INFO_BANNER_VISIBILITY:
      return {
        ...state,
        hideInfoBanner: [...state.hideInfoBanner, action.payload],
      };
    case actions.SET_CURRENT_TASK_WIDGET_DATA:
      return {
        ...state,
        currentStep: action.payload.step,
        currentProduct: action.payload.product,
      };
    case actions.COMPLETE_ONBOARDING_TASK:
      return {
        ...state,
        completedOnboardingTasks: [
          ...state.completedOnboardingTasks,
          action.payload.setup_guide.completed_key,
        ],
      };
    default:
      return state;
  }
}
