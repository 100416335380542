import {
  defaultColor,
  defaultGradientColorCode,
  fontDefaultColor,
  allTimezones,
} from './constant';
import { map, upperFirst, lowerCase } from 'lodash';
import momentTimeZone from 'moment-timezone';
import settings from '../../settings';
import {topicKeyToTextMap} from './Webhooks/constants';

export const getChatColors = (appearanceData, colorValues) => {
  let backgroundColor;
  let iconBackgroundColor;
  let fontColor;
  let iconGradientColorValue;

  if (['gradient', 'solid'].includes(appearanceData.background_type)) {
    const isGradient = appearanceData.background_type === 'gradient';
    map(colorValues[isGradient ? 'gradient' : 'solid'], (data, key) => {
      if (key === appearanceData.background_value) backgroundColor = data;
    });
  }
  if (['gradient', 'solid'].includes(appearanceData.icon_background_type)) {
    const isGradient = appearanceData.icon_background_type === 'gradient';
    map(colorValues[isGradient ? 'gradient' : 'solid'], (data, key) => {
      if (key === appearanceData.icon_background_value) {
        if (isGradient) {
          iconGradientColorValue = +key + 1;
          iconBackgroundColor = defaultGradientColorCode[key];
        } else {
          iconBackgroundColor = data;
        }
      }
      if (key === appearanceData.background_value) backgroundColor = data;
    });
  }
  if (appearanceData.font_color_value) {
    map(colorValues.font_colours, (data, key) => {
      if (key === appearanceData.font_color_value) fontColor = data;
    });
  }
  const backgroundCol = appearanceData.custom_value
      ? appearanceData.custom_value
      : appearanceData.backgroundColor
        ? appearanceData.backgroundColor.hex
        : backgroundColor || defaultColor,
    fontCol = appearanceData.font_custom_color
      ? appearanceData.font_custom_color
      : appearanceData.fontColor
        ? appearanceData.fontColor.hex
        : fontColor || fontDefaultColor,
    iconCol = appearanceData.icon_custom_color
      ? appearanceData.icon_custom_color
      : appearanceData.iconColor
        ? appearanceData.iconColor.hex
        : iconBackgroundColor || defaultColor;
  return {
    backgroundCol,
    fontCol,
    iconCol,
    iconGradientColorValue,
  };
};

export const getItemStyle = (isDragging, { transform, ...draggableStyle }) => {
  const styles = {
    ...draggableStyle,
  };
  if (!transform) {
    return styles;
  }
  return {
    ...styles,
    transform: `translate(0, ${transform.substring(
      transform.indexOf(',') + 1,
      transform.indexOf(')'),
    )})`,
  };
};

// a little function to help us with reordering the result
export const reorderList = (result, startIndex, endIndex) => {
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const getTimezoneOptions = () =>
  Object.entries(allTimezones)
    .reduce((selectOptions, zone) => {
      const offset = momentTimeZone.tz(zone[0]).format('Z');
      const label = `(GMT${offset}) ${zone[1]}`;

      selectOptions.push({
        value: zone[0],
        label: label,
        offset: offset,
      });

      return selectOptions;
    }, [])
    .sort((a, b) => a.offset - b.offset);

//validate Testing site or Production site
export const validateDomain = () => {
  return settings.REACT_DOMAIN_URL.includes("app.getgist.com");
};

export const transformTopics = (data) => {
  if (!data || typeof data !== "object" || Object.keys(data).length === 0) {
    // Return an empty array if the data is invalid
    return [];
  }
  
  return Object.entries(data).map(([categoryKey, topics]) => ({
    name: upperFirst(lowerCase(categoryKey)),
    topics: Array.isArray(topics)
      ? topics.map((key) => ({
        key,
        text: topicKeyToTextMap.get(key) || upperFirst(lowerCase(key)), // Use Map for Custom text, fallback to generateText
      }))
      : [],
  }));
}